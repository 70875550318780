import React, {useEffect} from 'react';
import './ItemPreview.scss';
import closeIcon from "./../assets/close-icon.svg";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock-upgrade';

const GET_MILESTONE = (lang, id) => {
    return gql`
        query GetMilestone {
            milestone(lang: ${lang}, id: ${id}) {
                name,
                description,
                technologies,
                personalRole,
                picturePath,
                link
            }
        }
    `;
}

export default function ItemPreview({lang, id, onClose, popupTexts}) {
    const {loading, data} = useQuery(GET_MILESTONE(lang, id));
    const targetRef = React.createRef(null);

    useEffect(() => {
        if (targetRef.current) {
            disableBodyScroll(targetRef.current);
        }
        
        return () => {
            clearAllBodyScrollLocks();
        };
    }, [targetRef]);

    return !loading && (
        <div className="Item-preview" ref={targetRef}>
        <div className="popup-bg" onClick={onClose}>
            <div className="popup-content" onClick={(e) => {e.stopPropagation()}}>
                <div className="popup-header">
                    <button className="close-button" onClick={onClose}><img alt="" src={closeIcon}/></button>
                    <h3>{data.milestone.name}</h3>
                </div>
                <div className="row">
                    <div className="description">
                        <h4>{popupTexts.description}</h4>
                        <p>
                            {data.milestone.description}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <h4>{popupTexts.technologies}</h4>
                    <p>
                        {data.milestone.technologies}
                    </p>
                </div>
                <div className="row">
                    <h4>{popupTexts.personalRole}</h4>
                    <p>
                        {data.milestone.personalRole}
                    </p>
                </div>
                {data.milestone.link &&
                    <div className="row center">
                        <a href={data.milestone.link} target="__blank">{popupTexts.linkText}</a>
                    </div>
                }
            </div>
        </div>
    </div>
    )
};
